










































































































import Vue from 'vue';
import { schemas } from '@truck-parking/tp-api';

export default Vue.extend({
  name: 'ForgotPassword',

  data() {
    return {
      email: '',
      codeSent: false,
      wait: false,
      confirmationCode: '',
      password: '',
      passwordRepeat: '',

      emailRules: [
        (v: string) => !!v || this.$i18n.t('validation-emailRequired'),
        (v: string) => new RegExp(schemas.signup.properties.emailAddress.pattern).test(v)
          || this.$i18n.t('validation-emailType'),
      ],
      codeRules: [
        (v: string) => new RegExp(schemas.confirmationCode.pattern || '').test(v)
          || this.$i18n.t('validation-confirmationCode'),
      ],
      passwordRequiredRules: [
        (v: string) => !!v || this.$i18n.t('validation-passwordRequired'),
      ],
      passwordRules: [
        (v: string) => (v && v.length >= schemas.signup.properties.password.minLength)
          || this.$i18n.t('validation-passwordMin'),
        (v: string) => (v && v.length <= schemas.signup.properties.password.maxLength)
          || this.$i18n.t('validation-passwordMax'),
      ],
    };
  },

  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.passwordRepeat
        || this.$i18n.t('validation-passwordMatch');
    },
  },

  mounted() {
    // Focus the email field in the beginning for correct input validation, as it is not part of form
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.email.$refs.input.focus();
  },

  methods: {
    async sendCode() {
      // There is a validate method for the v-form component, but since it does not have a valid
      // TS type, it shows this as an error. Nevertheless the code functions properly.
      if ((this.$refs.email as unknown as { validate(): boolean })?.validate()) {
        this.codeSent = true;
        this.wait = true;
        this.$api.sendCode({ emailAddress: this.email })
          .then(
            () => {
              this.$toaster.success(this.$i18n.t('forgotPassword-codeSent').toString());
              this.wait = false;
            },
            () => { this.codeSent = false; },
          );
      }
    },
    async resetPassword() {
      // There is a validate method for the v-form component, but since it does not have a valid
      // TS type, it shows this as an error. Nevertheless the code functions properly.
      if ((this.$refs.form as unknown as { validate(): boolean })?.validate()) {
        this.$api.resetPassword({
          emailAddress: this.email,
          password: this.password,
          confirmationCode: this.confirmationCode,
        })
          .then(() => {
            this.$toaster.success(this.$i18n.t('forgotPassword-resetSuccess').toString());
            this.$changeRoute('LogIn');
          });
      }
    },
  },
});
